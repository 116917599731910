import { observable, makeObservable, action, computed } from 'mobx';
import { ILocalization } from '../types';
import DataStore from './DataStore';
import OrderModuleStore from './OrderModuleStore';
import { MixedMediaStore } from './MixedMediaStore';
import { VariantStore } from './VariantStore';
import {
    IResponseData,
    IVariantSelection,
    IBookmarkArticleDataResponse,
    ArticleDataInjectionRequest,
    ViewType,
} from './types';
import Fetcher from './Fetcher';
import { ScrollLock } from '../helper/scrollLock';
import { showCaseContainerId } from '../components/orderModal/mediaFrame/mediaFrame';
import { isMobileOnly } from 'react-device-detect';

// eslint-disable-next-line no-var, @typescript-eslint/no-explicit-any
declare var _paq: any;

const bookmarkIntegrationSignature = 'ESCID.ESPP.ProductDetailsPage.Bookmark.OrderModal';
const bookmarkRequestEventName = 'ESCID.ESPP.Bookmark.RequestArticleDataInjection';
const bookmarkResponseEventName = 'ESCID.ESPP.Bookmark.ArticleDataInjection';

export class MainStore {
    public dataStore: DataStore;
    public orderModuleStore: OrderModuleStore;
    public mixedMediaStore: MixedMediaStore;
    public variantStore: VariantStore;
    public readonly scrollLock = new ScrollLock();
    public localization: ILocalization;
    public fetcher: Fetcher;
    public readonly piwikProEnableEcommerceTracking: boolean;
    public readonly esBrand = 'engelbert strauss';

    // @observable:
    public fetchedData: IResponseData;
    public cdnAssetsUrl: string;
    public play3d: boolean;
    public isMobileShop: boolean;
    public isEsApp: boolean;
    public isGlobal: boolean;
    public isSidepanel: boolean;
    public initialScrollPosition: number;

    public checkSelectedSizeType(variantSelection: IVariantSelection) {
        const isFirstSelectedTypeSoldOut =
            variantSelection.availableSizeTypes.find(sizeType => {
                if (sizeType.sizeTypeName == variantSelection.selectedSizeType)
                    return sizeType.sizes.every(size => {
                        return size.salesArticleVariantKey == '';
                    });
            }) != null;

        if (isFirstSelectedTypeSoldOut) {
            const nonSoldOutSizeType = variantSelection.availableSizeTypes.find(sizeType => {
                return !sizeType.sizes.every(size => {
                    return size.salesArticleVariantKey == '';
                });
            });
            if (nonSoldOutSizeType) {
                variantSelection.selectedSizeType = nonSoldOutSizeType.sizeTypeName;
                variantSelection.selectedSize = nonSoldOutSizeType.sizes.find(size => {
                    return size.salesArticleVariantKey;
                })?.size;
            }
        }
    }

    constructor(initialData: IResponseData, localization: ILocalization, itemOrigin: string,
                initialScrollPosition?: number, contentContainerId?: string, viewType?: ViewType) {
        this.dataStore = new DataStore(initialData, this);

        this.isMobileShop = initialData.contextData.isMobile;
        this.isEsApp = initialData.contextData.isEsApp;
        this.isGlobal = initialData.contextData.portal.includes('.GLOBAL.');
        this.isSidepanel = viewType === 'sidepanel';
        this.fetchedData = initialData;
        this.mixedMediaStore = new MixedMediaStore(this);
        this.initialScrollPosition = typeof initialScrollPosition === 'number' ? initialScrollPosition : 0;

        // If all sizes in selected size type are sold out
        // change first selected size type and first selected size
        this.checkSelectedSizeType(initialData.variantSelection);
        this.checkSelectedSizeType(this.dataStore.variantSelection);

        this.variantStore = new VariantStore(this);
        this.fetcher = new Fetcher(initialData.contextData.culture?.split('-')[0]);
        this.orderModuleStore = new OrderModuleStore(this, itemOrigin, contentContainerId);

        this.cdnAssetsUrl = initialData.contextData.baseUrlForAssets;

        this.localization = localization;
        this.play3d = false;

        this.piwikProEnableEcommerceTracking = initialData.contextData.piwikProEnableEcommerceTracking;

        makeObservable(this, {
            fetchedData: observable,
            cdnAssetsUrl: observable,
            play3d: observable,
            variantChanged: action,
            setFetchedData: action,
            togglePlay3D: action,
            getIsMobileView: computed,
        });

        this.trackVisitedArticle(initialData);

        if (typeof window !== 'undefined' && window.shell) {
            window.shell.subscribeTo(
                bookmarkRequestEventName,
                (request: ArticleDataInjectionRequest) => {
                    const response: IBookmarkArticleDataResponse = {
                        ...this.fetchedData.bookmark,
                        requester: request.requester,
                    };
                    /*
                    Condition needed for https://jira.ztna.cid.com/browse/ESPP-9930
                    to prevent that color change in order modal from Mix&Match shutter 
                    cause Shortlist modal color change. 
                    */
                    if (!request.requester || request.requester == null)
                        window.shell.publishTo(bookmarkResponseEventName, response);
                },
                bookmarkIntegrationSignature
            );
        }
    }

    public trackVisitedArticle(data: IResponseData) {
        if (!this.piwikProEnableEcommerceTracking)
            return;

        const caseInsensitiveParameters = new URLSearchParams();

        if (typeof window !== 'undefined' && typeof window.location !== 'undefined') {
            const originParameters = new URL(window.location.href).searchParams;
            originParameters.forEach((value: string, key: string) => {
                caseInsensitiveParameters.append(key.toLowerCase(), value);
            });
        }

        const trackedData = [
            {
                sku: data.orderModule.articleInfo.salesArticleNo,
                name: data.orderModule.articleInfo.title,
                category: data.breadcrumb.category.navigationKeyPath.split('/'),
                price: data.orderModule.articlePrice.basicPrice.netValue,
                brand: (data.orderModule.articleInfo?.brand ?? '').toLocaleLowerCase() === this.esBrand ?
                    'Strauss' : 'other Brand',
                variant: data.orderModule.articleInfo.salesArticleVariantKey,
                customDimensions: {
                    '1': data.orderModule.articleInfo.masterArticleNo.toString(),
                    '2': data.variantSelection.selectedColor?.code ?
                        data.variantSelection.selectedColor.code.toString(): '',
                    '3': data.variantSelection.selectedSize?.name,
                    '4': data.variantSelection.selectedModel?.code ?
                        data.variantSelection.selectedModel.code.toString() : '',
                    '5': 'MODAL_ORDER/' + this.orderModuleStore.itemOrigin,
                }
            }
        ];

        if (typeof _paq !== 'undefined')
            _paq.push(['ecommerceProductDetailView', trackedData]);
    }

    // action
    variantChanged(fetchedData: IResponseData) {
        this.setFetchedData(fetchedData);
        this.trackVisitedArticle(fetchedData);
        this.dataStore.updateData(fetchedData);
        this.mixedMediaStore.dataUpdate(fetchedData.mixedMedia);

        window.shell.publishTo('ESPP.OrderModal.Update', {
            showCaseContainerId,
            response: fetchedData,
            restartAnimation: this.play3d,
        });
    }

    setFetchedData(newFetchedData: IResponseData) {
        this.fetchedData = newFetchedData;
    }

    togglePlay3D() {
        this.play3d = !this.play3d;
        window.shell.publishTo('SCS.PlayPauseAnimation',
            { containerId: showCaseContainerId, play3d: this.play3d });
    }

    // Computed
    get getIsMobileView(): boolean {
        return isMobileOnly || this.isEsApp || this.isSidepanel;
    }
}
